import clsx from 'clsx';
import { navigate } from 'gatsby';
import React, { useContext, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { authStoreContext } from '../../stores/AuthStore';
import { useQueryParam } from '../../utils/queryParams';

interface Props {}

const Confirm = (props: Props) => {
	const { auth, confirmPassword } = useContext(authStoreContext);

	const [confirming, setIsConfirming] = useState(true);
	const [error, setError] = useState(false);
	const code = useQueryParam('code');

	useEffect(() => {}, [
		confirmPassword({ code })
			.then(() => {
				// TODO make handler for success auth
				navigate('/user/profile');
			})
			.catch(() => setError(true)),
	]);

	return (
		<>
			<Helmet>
				<title>Confirming password</title>
			</Helmet>
			<div className="centered">
				<div
					className={clsx('p-4 max-w-md bg-blue-500 text-white rounded-sm', {
						'bg-red-500': error,
					})}
				>
					{error ? 'Invalid message code' : 'Confirming password ...'}
				</div>
			</div>
		</>
	);
};

export default Confirm;
